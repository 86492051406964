exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-newsletter-signup-js": () => import("./../../../src/pages/newsletter-signup.js" /* webpackChunkName: "component---src-pages-newsletter-signup-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-newsletter-js": () => import("./../../../src/pages/thank-you-newsletter.js" /* webpackChunkName: "component---src-pages-thank-you-newsletter-js" */),
  "component---src-pages-traveller-enquiry-js": () => import("./../../../src/pages/traveller-enquiry.js" /* webpackChunkName: "component---src-pages-traveller-enquiry-js" */),
  "component---src-templates-accommodation-js": () => import("./../../../src/templates/accommodation.js" /* webpackChunkName: "component---src-templates-accommodation-js" */),
  "component---src-templates-destination-js": () => import("./../../../src/templates/destination.js" /* webpackChunkName: "component---src-templates-destination-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-tour-brochure-js": () => import("./../../../src/templates/tourBrochure.js" /* webpackChunkName: "component---src-templates-tour-brochure-js" */),
  "component---src-templates-tour-js": () => import("./../../../src/templates/tour.js" /* webpackChunkName: "component---src-templates-tour-js" */),
  "component---src-templates-travel-style-js": () => import("./../../../src/templates/travelStyle.js" /* webpackChunkName: "component---src-templates-travel-style-js" */),
  "component---src-templates-traveller-brochure-js": () => import("./../../../src/templates/travellerBrochure.js" /* webpackChunkName: "component---src-templates-traveller-brochure-js" */),
  "component---src-templates-traveller-tour-js": () => import("./../../../src/templates/travellerTour.js" /* webpackChunkName: "component---src-templates-traveller-tour-js" */)
}

